import React from "react"

const Features = props => {
  return (
    <div className="text-center mt-5" id="features">
      <div className={`section-title color-${props.categoryName}`}>
        What differentiates us?
      </div>
      <div className={`section-text color-${props.categoryName}`}>
        We constantly strive to provide you with the best service:{" "}
        <b>hassle-free, 24/7 customer support and reasonable prices.</b>
      </div>
      <div className={`section-text color-${props.categoryName} mt-10`}>
        Boostik also ensure:
      </div>
      <div className={`section-text color-${props.categoryName}`} style={{marginTop:"0"}}>
        <div className="section-content">
          <div className="flex justify-center flex-col items-center md:flex-row">
            <div
              className={`features-menu-button pricing-menu-button-selected color-${props.categoryName} shadow-lg w-52 mt-5 mr-10`}
            >
              <div>
                <i className="fa fa-shipping-fast text-5xl"></i>
              </div>
              <div className="mt-5 font-bold">Quick Delivery</div>
              <div className="text-base mt-5">
                Orders are automatically submitted to our system and will be
                processed instantly.
              </div>
            </div>
            <div
              className={`features-menu-button pricing-menu-button-selected color-${props.categoryName} shadow-lg w-52 mt-5 mr-10`}
            >
              <div>
                <i className="fa fa-user-circle text-5xl"></i>
              </div>
              <div className="mt-5 font-bold">Trust & Safety</div>
              <div className="text-base mt-5">
                We will never ask for your password or any other sensitive data
                in order to provide our services.
              </div>
            </div>
            <div
              className={`features-menu-button pricing-menu-button-selected color-${props.categoryName} shadow-lg w-52 mt-5 mr-10`}
            >
              <div>
                <i className="fa fa-ticket-alt text-5xl"></i>
              </div>
              <div className="mt-5 font-bold">Ease of use</div>
              <div className="text-base mt-5">
                Our added value is how simple and easy it’s to use our services.
                See for yourself!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
