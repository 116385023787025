import "regenerator-runtime/runtime"
import { getProductsList } from "../domain/datasources/products.remote"

export const handleGetProductsList = (category, subCategory, callback) => {
  async function asyncCall() {
    const response = await getProductsList(category, subCategory)
    if (response.state === "success") {
      callback(null, response.productsList)
    }
  }
  return asyncCall()
}
