import React from "react"

const HowItWorks = props => {
  return (
    <div
      id="howItWorks"
      className={`w-full flex flex-col justify-center background-gradient-${props.viewName}`}
    >
      <div className="container mx-auto text-center p-10 mt-5">
        <div className="section-title color-white">
          <b>How it works?</b>
        </div>
      </div>
      <div className="container flex justify-center mx-auto p-10">
        <div>
          <div className="flex items-center container mx-auto mb-4 color-white lg:pb-10 lg:pl-10 lg:pr-10">
            <div className="mr-12 text-xl border-2 border-white rounded-full px-3 py-2">
              <i className="fas fa-hand-pointer"></i>
            </div>
            <div>
              <div className="color-white text-lg">
                Step 1: <b>Choose a plan</b>
              </div>
              <div className="color-white">
                Select the plan that best suits your needs. Without any
                commitment of permanence.
              </div>
            </div>
          </div>
          <div className="flex items-center container mx-auto mb-4 color-white lg:pb-10 lg:pl-10 lg:pr-10">
            <div className="mr-12 text-xl border-2 border-white rounded-full px-3 py-2">
              <i className="fas fa-edit"></i>
            </div>
            <div>
              <div className="text-lg">
                Step 2: <b>Customize campaign</b>
              </div>
              <div>
                Customize your campaign, choose more options to get powerfully boost.
              </div>
            </div>
          </div>
          <div className="flex items-center container mx-auto mb-4 color-white lg:pb-10 lg:pl-10 lg:pr-10">
            <div className="mr-12 text-xl border-2 border-white rounded-full px-3 py-2">
              <i className="fas fa-bullhorn"></i>
            </div>
            <div>
              <div className="text-lg">
                Step 3: <b>We receive your link</b>
              </div>
              <div>
                When we receive the link you want to promote, we get to work
                and the process begins!
              </div>
            </div>
          </div>
          <div className="flex items-center container mx-auto mb-4 color-white lg:pb-10 lg:pl-10 lg:pr-10">
            <div className="mr-12 text-xl border-2 border-white rounded-full px-3 py-2">
              <i className="fas fa-rocket"></i>
            </div>
            <div>
              <div className="text-lg">
                Step 4: <b>Enjoy results!</b>
              </div>
              <div>
                Watch your profile grow. You will see the results in a short
                time. It has never been so easy.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background-gray-wave-separator"></div>
    </div>
  )
}

export default HowItWorks
