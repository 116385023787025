/* eslint-disable no-useless-escape */
export const extractPackage = name => {
  const regex = new RegExp("([^\\s]*)")
  const groups = name.match(regex)

  if (groups[1]) {
    return groups[1]
  }

  return name
}
