import "regenerator-runtime/runtime"
import { resetExistingCart } from "../domain/datasources/carts.remote"

export const handleResetExistingCart = (cartId, callback) => {
  async function asyncCall() {
    const response = await resetExistingCart(cartId)
    if (response.state === "success") {
      callback(null, response.message)
    }
  }
  return asyncCall()
}