import React from "react"
import Fast from "./../../static/img/fast.svg"
import Secure from "./../../static/img/secure.svg"
import Happy from "./../../static/img/happy.svg"
import Support from "./../../static/img/support.svg"

const Main = props => {
  return (
    <div className={`background-gradient-${props.viewName}`}>
      <div className="guarantee-icons-container grid grid-cols-2 md:grid-cols-4">
        <div className="flex justify-center items-center text-white p-4">
          <img src={Fast} style={{ width: "40px", marginRight: "5px" }} />{" "}
          FASTEST DELIVERY
        </div>
        <div className="flex justify-center items-center text-white p-4">
          <img src={Secure} style={{ width: "40px", marginRight: "5px" }} />{" "}
          SECURITY & PRIVACY
        </div>
        <div className="flex justify-center items-center text-white p-4">
          <img src={Happy} style={{ width: "40px", marginRight: "5px" }} />{" "}
          RESULT GUARANTEED
        </div>
        <div className="flex justify-center items-center text-white p-4">
          <img src={Support} style={{ width: "40px", marginRight: "5px" }} />{" "}
          24/7 CUSTOMER SUPPORT
        </div>
      </div>
    </div>
  )
}

export default Main
