/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { handleGetProductsList } from "../../data/usecases/getproductslist"
import { handleGetCartIdFromCookie } from "../../data/usecases/getcartidfromcookie"
import { handleCreateCart } from "../../data/usecases/createnewcart"
import { handleResetExistingCart } from "../../data/usecases/resetexisitngcart"
import { handleAddItemsToCart } from "../../data/usecases/additemstocart"
import { Button, notification } from "antd"
import { navigate } from "gatsby"
import { currencyToSymbol } from "../../data/managers/currencytosymbol"
import { extractPackage } from "../../data/managers/extract-package"

const getIcon = category => {
  if (category === "tiktok") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2859 3333"
        width="21px"
        height="21px"
        fill="#4fb5c0"
        style={{marginRight: '5px'}}
      >
        <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z"></path>
      </svg>
    )
  }
  if (category === "instagram") {
    return <i className={`bx bxl-${category} text-2xl`} style={{marginRight: '5px'}}></i>
  }
  if (category === "soundcloud") {
    return <i className={`bx bxl-${category} text-2xl`} style={{marginRight: '5px'}}></i>
  }
}

const Pricing = props => {
  const [existingCartId, setExistingCartId] = useState(null)
  const [pricesList, setPricesList] = useState([])
  const [selectedProductPriceInfo, setSelectedProductPriceInfo] = useState([])
  const [startItem, setStartItem] = useState(0)
  const [lastItem, setLastItem] = useState(4)
  const sendNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      top: 100,
    })
  }

  const goToCheckoutPage = () => navigate(`/checkout`)

  const addItemToCart = itemReference => {
    handleAddItemsToCart(existingCartId, [itemReference], (error, message) => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      goToCheckoutPage()
    })
  }

  const resetExistingCart = itemReference => {
    handleResetExistingCart(existingCartId, error => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      addItemToCart(itemReference)
    })
  }

  const createNewCartAndAddItem = itemReference => {
    handleCreateCart(itemReference, (error, cartInfo) => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      document.cookie = `cart_id=${cartInfo.cartId}; path=/`
      setExistingCartId(cartInfo.cartId)
      goToCheckoutPage()
    })
  }

  const buyNowAction = itemReference => {
    return !existingCartId
      ? createNewCartAndAddItem(itemReference)
      : resetExistingCart(itemReference)
  }

  const getProductInfoAndSettedAsSelected = () => {
    let currentPricesList = pricesList
    handleGetProductsList(
      props.categoryName,
      props.productName,
      (error, productsList) => {
        if (error) {
          return alert(error)
        }
        currentPricesList[
          `${props.categoryName}_${props.productName}`
        ] = productsList
        setPricesList(currentPricesList)
        setSelectedProductPriceInfo(productsList)
      }
    )
  }

  useEffect(() => {
    if (pricesList[`${props.categoryName}_${props.productName}`]) {
      setSelectedProductPriceInfo(
        pricesList[`${props.categoryName}_${props.productName}`]
      )
    } else {
      getProductInfoAndSettedAsSelected()
    }
  }, [props.productName])

  useEffect(() => {
    if (selectedProductPriceInfo.length > 4) {
      setStartItem(0)
      setLastItem(4)
    }
  }, [selectedProductPriceInfo])

  useEffect(() => {
    setExistingCartId(handleGetCartIdFromCookie())
  }, [])

  return (
    <div id="pricing">
      <div className="container mx-auto pb-10 hidden md:inline">
        <div className="flex flex-wrap justify-center items-center mb-10">
          {startItem !== 0 && (
            <div>
              <button
                onClick={() => {
                  const start = startItem
                  const last = lastItem
                  setStartItem(start - 1)
                  setLastItem(last - 1)
                }}
              >
                <i
                  className={`bx bx-left-arrow text-3xl color-${props.categoryName}`}
                ></i>
              </button>
            </div>
          )}
          {selectedProductPriceInfo && selectedProductPriceInfo.length > 0 ? (
            [...selectedProductPriceInfo]
              .slice(startItem, lastItem)
              .map((item, index) => (
                <div key={index} className="pricing-card product-shadow">
                  <div>
                    <div
                      className={
                        `pricing-card-subtitle color-${props.categoryName}` +
                        (item.popular ? " pricing-popular" : "")
                      }
                    >
                      {item.popular ? (
                        <span className="product-recommended">RECOMMENDED</span>
                      ) : (
                        ""
                      )}
                      <p className="flex items-center space-x-2 ptitle">
                        {getIcon(props.categoryName)}
                        {extractPackage(item.name)}
                        {item.popular && (
                          <span className="text-yellow-400">&#9733;</span>
                        )}
                      </p>
                      <p className="font-bold text-sm">{item.description}</p>
                    </div>
                  </div>
                  <div
                    className={`pricing-card-header background-gradient-${props.categoryName}`}
                    style={{
                      padding: `${item.popular === true ? "25px" : "25px"}`,
                    }}
                  >
                    <div className="pricing-card-title">
                      {currencyToSymbol(item.currency)}
                      {item.amount}
                      <p
                        className="text-sm font-weight-light line-through"
                        style={{
                          fontSize: "20px",
                          color: "#fbbf24",
                          marginBottom: "8px",
                        }}
                      >
                        {currencyToSymbol(item.currency)}
                        {Math.round(item.amount * 1.3)}
                      </p>
                      <p
                        className="text-lg font-weight-bold capitalize"
                        style={{
                          marginBottom: 0,
                          fontSize: "15px",
                          textTransform: "uppercase",
                        }}
                      >
                        {props.categoryName} {item.subCategory}
                      </p>
                    </div>
                  </div>
                  <div className="pricing-card-content">
                    <div>100% Safe</div>
                    <div>One single payment</div>
                    <div>Delivery within {item.delivery}</div>
                    <div>24/7 Support</div>
                    <div>Money Back Guarantee</div>
                    <Button
                      type="primary"
                      shape="round"
                      style={{ marginTop: "15px" }}
                      className={`header-section-button background-color-${
                        props.categoryName
                      } ${
                        item.popular ? "header-section-button-recomended" : ""
                      }`}
                      onClick={() => buyNowAction(item.sku)}
                    >
                      <span style={{ textTransform: "uppercase" }}>
                        Create Campaign
                      </span>
                    </Button>
                  </div>
                </div>
              ))
          ) : (
            <div></div>
          )}
          {lastItem !== selectedProductPriceInfo.length && (
            <div>
              <button
                onClick={() => {
                  const start = startItem
                  const last = lastItem
                  setStartItem(start + 1)
                  setLastItem(last + 1)
                }}
              >
                <i
                  className={`bx bx-right-arrow text-3xl color-${props.categoryName}`}
                ></i>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="container mx-auto pb-10 inline md:hidden">
        <div className="flex items-center flex-col">
          {selectedProductPriceInfo && selectedProductPriceInfo.length > 0 ? (
            selectedProductPriceInfo.map((item, index) => (
              <div key={index} className="pricing-card product-shadow w-10/12">
                <div>
                  <div
                    className={
                      `pricing-card-subtitle color-${props.categoryName}` +
                      (item.popular ? " pricing-popular" : "")
                    }
                  >
                    {item.popular ? (
                      <span className="product-recommended">RECOMMENDED</span>
                    ) : (
                      ""
                    )}
                    <p className="flex items-center space-x-2 ptitle">
                      {getIcon(props.categoryName)}
                      {extractPackage(item.name)}
                      {item.popular && (
                        <span className="text-yellow-400">&#9733;</span>
                      )}
                    </p>
                    <p className="font-bold text-sm">{item.description}</p>
                  </div>
                </div>
                <div
                  className={`pricing-card-header background-gradient-${props.categoryName}`}
                  style={{
                    padding: `${item.popular === true ? "25px" : "25px"}`,
                  }}
                >
                  <div className="pricing-card-title">
                    {currencyToSymbol(item.currency)}
                    {item.amount}
                    <p
                      className="text-sm font-weight-light line-through"
                      style={{
                        fontSize: "20px",
                        color: "#fbbf24",
                        marginBottom: "8px",
                      }}
                    >
                      {currencyToSymbol(item.currency)}
                      {Math.round(item.amount * 1.3)}
                    </p>
                    <p
                      className="text-lg font-weight-bold capitalize"
                      style={{
                        marginBottom: 0,
                        fontSize: "15px",
                        textTransform: "uppercase",
                      }}
                    >
                      {props.categoryName} {item.subCategory}
                    </p>
                  </div>
                </div>
                <div className="pricing-card-content">
                  <div>100% Safe</div>
                  <div>One single payment</div>
                  <div>Delivery within {item.delivery}</div>
                  <div>24/7 Support</div>
                  <div>Money Back Guarantee</div>
                  <Button
                    type="primary"
                    shape="round"
                    style={{ marginTop: "15px" }}
                    className={`header-section-button background-color-${
                      props.categoryName
                    } ${
                      item.popular ? "header-section-button-recomended" : ""
                    }`}
                    onClick={() => buyNowAction(item.sku)}
                  >
                    <span style={{ textTransform: "uppercase" }}>
                      Create Campaign
                    </span>
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Pricing
